.main-screen{
    margin-top: 100px !important;
    height: 100%;
    width: 100%;
}

/* .main-screen-bg{
    background-image: url(../bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: -100 !important;
} */

::-webkit-scrollbar {
    width: 10px !important;
}
::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 10px !important;
}
::-webkit-scrollbar-thumb {
    background: rgb(172, 172, 172) !important;
    border-radius: 5px !important;
}

.employee-name-pin-auth{
    background-color: #ffffff !important;
    border-radius: 10px !important;
    width: max-content;
    padding: 5px 10px;
    border: 1px solid #000000;
}
.employee-pin-enter{
    background-color: #ffffff46 !important;
    backdrop-filter: blur(10px);
    border-radius: 10px !important;
    width: max-content;
    padding: 4px 5px;
    box-shadow: 0px 0px 5px 0px #0000005e;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }